$("#menu-toggle").click(function (e) {
        e.preventDefault();
        $("#wrapper").toggleClass("active");
});


// $('.seach-add-new').click(function(e) {
// e.preventDefault();
          function hidebtn() {
      
                $(".seach-add-new").hide();
              }

        // var x = document.getElementsByClassName(".seach-add-new");
        // if (x.style.display === "none") {
        //         x.style.display = "block";
        // } else {
        //         x.style.display = "none";
        // }
